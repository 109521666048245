.Timeline {
  font-family: sans-serif;
  text-align: center;
  font-family: "Roboto", sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* estilos do menu mobile */
.nav-container {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.6s ease-in-out;
}

.open {
  max-height: 700px;
}

.close {
  max-height: 0;
}
