.glide__bullet[class*="active"] {
  background-color: #00679A;
}

.glide__slide {
  height: 260px;
}
.glide__slide img {
  height: 150px;
  margin-left: auto;
  margin-right: auto;
}