.slide-container {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.8s ease-in-out;
}

.slide-down {
  max-height: fit-content;
}

.slide-up {
  max-height: 560px;
}